@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* main페이지 최상단  */
  .main-left-controller span {
    @apply bg-white bg-opacity-0;
  }
}
